import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import moment from 'moment';
import { or } from 'ramda';
import CookieService from '../services/CookieService';
import { getDateString, insertString } from '../utility/misc';
import Cognito from '../utility/CognitoAuth';
const _base_accounts_url = 'https://accounts-dev.tria.connectria.com/labs/portal/';
//create a cache axios instance
function _createCacheCall(extraConfig = {}) {
  return axios.create({
    baseURL: '/',
    headers: { 'x-auth-token': CookieService.getCookie('session_id') },
    // disable the default cache and set the cache flag
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' }),
    ...extraConfig,
  });
}
async function _createCacheCallNew(extraConfig = {}) {
  return axios.create({
    baseURL: _base_accounts_url,
    headers: { Authorization: await Cognito.getIdToken() },
    // disable the default cache and set the cache flag
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' }),
    ...extraConfig,
  });
}

async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
    baseURL: _base_accounts_url,
    headers: { Authorization: idToken },
  });
  //req.defaults.headers['Authorization'] = await Cognito.getIdToken();
  return req;
}
const base_api = 'https://dev.tria.connectria.com';
const _portalTicketsPlugin = '/plugin/support';
const portal_url = 'https://testportal.mh.connectria.com';
const files_url = 'https://files.dev.tria.connectria.com/';
var ticketTypes = [];
var inbox = [];
let ticketsByStatus = {};
var ticketDetail = null;
var ticketHistory = null;
var lastTicketDetailId = null;
var lastTicketHistoryId = null;
var ticketAudits = null;
var ticketPriorityMetrics = null;
var userId = null;
var users = [];
var usersMap = {};
var userRatings = null;

function _inboxReturn(limit, offset) {
  return inbox.slice(offset);
}
function _virtualUpdateUserRating(item) {
  if (userRatings == null) userRatings = [];

  //if item exists update if not add
  let indx = userRatings.findIndex((x) => x.id == item.id);
  if (indx > -1) {
    userRatings[indx] = item;
  } else {
    userRatings.push(item);
  }
}
function _virtualDeleteUserRating(id) {
  userRatings = userRatings.filter((item) => item.id !== id);
}

async function _getAnnoucments(params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/getAnnoucments/getAllLike`, params);
    return request;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function _getPortalAssumeToken(params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`/${orgId}/get_assume_token`, params);
    return request;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function _getUserTicketRatings(id, params = {}) {
  if (userRatings != null) {
    return userRatings;
  }
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/ticket_history_ratings/getAll`, params);

    userRatings = Array.isArray(request.data.response.data.ticket_history_ratings)
      ? request.data.response.data.ticket_history_ratings
      : [];
    return userRatings;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function _getTicketDetail(id, params = {}) {
  if (lastTicketDetailId == id && ticketDetail) {
    return ticketDetail;
  }
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(
      `/${orgId}/tickets/get/${id}?ar_assoc=user,meta,ticket_attachments,ticketcategory,ticket_type,ticket_carbon_copies,enteredby`,
      params
    );
    lastTicketDetailId = id;
    ticketDetail = request.data.response.data.tickets[0];
    return ticketDetail;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getDevice(deviceID, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(
      `${orgId}/devices/get/${deviceID}?ar_assoc=operatingsystems,meta,addresses,devicetype`,
      params
    );
    return request.data.response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getPortalDevice(deviceID, params = {}) {
  let orgId = CookieService.getCookie('OrganizationId');
  let url = `${orgId}/devices/get`;
  if (deviceID) {
    url += '/' + deviceID;
  }
  try {
    const req = await defaultRequest();
    let request = await req.get(url, { params: params });
    return request.data.response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getAllDevices(params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/tria/deviceList`, params);
    return request.data.response.data.devices || [];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getAllDevicesLike(payload, params = {}) {
  try {
    params.useCache = true;
    let http = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await http.get(`${orgId}/devices/getAllLike?`, { params: payload, ...params });
    return request.data.response.data.devices || [];
  } catch (error) {
    console.log(error);
    throw error;
  }
}
async function _getAllDevicesById(payload, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/devices/getAll?`, { params: payload, ...params });
    return request.data.response.data.devices || [];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getAllDeviceCount(payload, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/devices/getCount`, { params: payload, ...params });
    // let request = await axios.get(base_api + `/plugin/support/portal/devices/getCount`, { params: payload, ...params });
    return request.data.response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getAllDeviceTypes(payload, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/devicetypes/getAll`, { params: payload, ...params });
    return request.data.response.data.devicetypes || [];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getDeviceOSType(id, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/ostypes/get/${id}`, params);
    return request.data.response.data.ostypes[0];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _updateDevice(id, data, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.put(`${orgId}/devices/update/${id}`, data, params);
    return request.data.response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getAllTicketsCount(filter, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/tickets/getCount`, filter, params);
    return request.data.response.data.tickets[0];
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getAllTicketsLike(filter, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.post(`${orgId}/tickets/getAllLike`, filter, params);
    if (request.data.response.data.tickets == null) {
      return [];
    }
    return request.data.response.data.tickets;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getTicketHistory(id, params = {}) {
  if (lastTicketHistoryId === id && ticketHistory !== null) {
    return ticketHistory.ticket_histories;
  }
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/ticket_histories/getAll?ar_assoc=user&ticket_id=${id}`, params);
    lastTicketHistoryId = id;
    ticketHistory = request.data.response.data;

    if (ticketHistory.ticket_histories) {
      return ticketHistory.ticket_histories.reverse();
    }
    return null;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
export function _removeCachedTicketHistory() {
  lastTicketHistoryId = null;
  ticketHistory = null;
}

export function _removeCachedUsers() {
  users = [];
  usersMap = {};
}

async function _downloadAttachment(id) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/ticket_attachments/getRawFile/${id}`);
    return request;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _addCommentAttachment(id, filename, content) {
  let hasFile = filename && filename.length > 0 && content && content.length > 0;
  if (!hasFile) return false;

  let payload = {
    attachment: {
      filename: insertString(filename, getDateString(), '.'),
      json: content,
    },
    comment: {
      comment: 'Attached file: ' + filename,
    },
  };

  let request;
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    request = await req.post(`${orgId}/tickets/addAttachment/${id}?origin=tria`, payload);
    // if upload was successful, clear cache
    if (request.data.response.status) {
      ticketHistory = null;
      ticketAudits = null;

      // clear ticket detail cache when attachments are added
      if (hasFile) {
        console.log('clearing ticket cache after attachment');
        ticketDetail = null;
      }
    }
    return request.data.response.message;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function _postNewComment(id, comment) {
  if (!comment || comment.length < 3) return;
  try {
    var payload = {
      comment: {
        comment: comment,
      },
    };
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.post(`${orgId}/tickets/addComment/${id}`, payload);

    //if update was successful, clear cache
    if (request.data.response.status) {
      ticketHistory = null;
      ticketAudits = null;
    }
    return request.data.response.message;
  } catch (error) {
    console.log(error);
  }
}

async function _getTicketTypes(params = {}) {
  if (ticketTypes.length != 0) {
    return ticketTypes;
  }
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(`${orgId}/ticket_types/getAll`, params);
    ticketTypes = request.data.response.data.ticket_types;

    return ticketTypes;
  } catch (error) {
    console.log(error);
  }
}

async function _getAllTicketsByStatus(status) {
  if (ticketsByStatus[status] != null) {
    return ticketsByStatus[status];
  }
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(
      `${orgId}/tickets/getAll?limit=100&select=tickets.id,+subject,device,ticket_type_id,priority,created,+status,user_id,+lastUpdate&sort_by=lastUpdate+desc&status=` +
      status
    );
    ticketsByStatus[status] = request.data.response.data.tickets;
    return ticketsByStatus[status];
  } catch (error) {
    console.log(error);
  }
}

async function _getTicketAudits(limit, params = {}) {
  if (ticketAudits != null) {
    return ticketAudits;
  }
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.get(
      `${orgId}/tickets/audit?limit=${limit != null ? limit : 15}&sort_by=lastUpdate+desc`,
      params
    );
    ticketAudits = request.data.response.data.ticket_audits;
    return ticketAudits;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _closeTicket(id, comment, rating) {
  try {
    var payload = {
      comment: {
        comment: comment,
      },
    };
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    await req.put(`${orgId}/tickets/closeTicket/${id}`, payload);
    if (rating) {
      var payloadRating = {
        closed_ticket_rating: {
          ticket_id: id,
          closed_ticket_rating_status_id: rating,
          comment: comment,
        },
      };
      await req.post(`${orgId}/closed_ticket_ratings/create`, payloadRating);
    }
    //clear cache
    ticketDetail = null;
  } catch (error) {
    console.log(error);
  }
}

async function _getChildTicket(id) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    return await req.get(`${orgId}/tickets/child_ticket/getAll?parent_ticket_id=${id}`);
  } catch (error) {
    console.log(error);
  }
}

async function _reopenTicket(id, comment) {
  try {
    var payload = {
      comment: {
        comment: comment,
      },
    };
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    await req.put(`${orgId}/tickets/reopenTicket/${id}`, payload);
    //clear cache
    ticketDetail = null;
  } catch (error) {
    console.log(error);
  }
}

async function _getTicketsFiltered(filter, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await req.post(`${orgId}/tickets/getAll`, filter, params);
    if (request.data.response.data.tickets == null) {
      return [];
    }
    return request.data.response.data.tickets;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getTicketCategories(params = {}) {
  try {
    params.useCache = true;
    let http = await _createCacheCallNew();
    let orgId = CookieService.getCookie('OrganizationId');
    let request = await http.get(`${orgId}/ticketcategories/getAll?customeraccess=1&active=1`, params);
    return request.data.response.data?.ticketcategories?.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getAllUsersLike(params = {}, filterConnectria = true) {
  if (users.length !== 0) {
    return users;
  }
  try {
    params.useCache = true;
    let http = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    const customer_id = CookieService.getCookie('CustomerId');
    let response = await http.get(`${orgId}/users/getAllLike`, {
      ...params,
      params: { ...params?.params, customer_id },
    });
    users = response.data.response.data.users || [];

    if (filterConnectria) {
      users = (users || []).filter((user) => {
        return !user.email.includes('@connectria.com');
      });
    }

    for (let n in users) {
      usersMap[users[n].id] = users[n];
    }

    return users;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getPreferences(filter, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let response = await req.get(`${orgId}/preferences/get`, { params: filter }, params);
    return response.data.response.data.preferences;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _getParamData(params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let response = await req.get(`${orgId}/get_param_data`, params);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function _exportAllTickets(months, params = {}) {
  try {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    let response = await req.get(`${orgId}/tria/exportAllTickets?months=` + months, params);
    if (response.data.response.data.tickets == null) {
      return [];
    }
    return response.data.response.data.tickets;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const ConnectriaTicketsService = {
  getAllUsersLike: async function (params = {}, filterConnectriaUsers = true) {
    return await _getAllUsersLike(params, filterConnectriaUsers);
  },
  getPreferences: async function (filter, params = {}) {
    filter['user_id'] = await ConnectriaTicketsService.portalUserId(params);
    return await _getPreferences(filter, params);
  },
  updateTicket: async function (id, data) {
    try {
      const req = await defaultRequest();
      let orgId = CookieService.getCookie('OrganizationId');
      await req.put(`${orgId}/tickets/update/${id}`, data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  updateUsersTicketCarbonCopies: function (ticket) {
    for (let n in ticket.ticket_carbon_copies) {
      ticket.ticket_carbon_copies[n].user = usersMap[ticket.ticket_carbon_copies[n].user_id];
    }
  },
  deleteTicketCarbonCopy: async function (ccID) {
    try {
      const req = await defaultRequest();
      let orgId = CookieService.getCookie('OrganizationId');
      await req.delete(`${orgId}/ticket_carbon_copies/delete/${ccID}`);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  getAttachmentUrl: async function (ticket_id, attachment_id) {
    let assumeToken = await _getPortalAssumeToken();
    return (
      files_url + `?id=${attachment_id}&tid=${ticket_id}&api_key=${assumeToken.data.portal_assume_token}&origin=tria`
    );

    //return portal_url + `/support/getAttachment?id=${attachment_id}&tid=${ticket_id}&api_key=${assumeToken.data.portal_assume_token}`;
  },
  getPortalAssumeToken: async function () {
    return await _getPortalAssumeToken;
  },
  getAnnoucments: async function (params) {
    return await _getAnnoucments(params);
  },
  getTicketCommentRatings: async function (id, params) {
    return await _getUserTicketRatings(id, params);
  },
  createTicketCommentRating: async function (id, rating, comment = '') {
    let orgId = CookieService.getCookie('OrganizationId');
    let url = `${orgId}/ticket_history_ratings/create`;
    try {
      let payload = {
        ticket_history_rating: {
          comment: comment,
          ticket_history_id: id,
          rating: rating,
        },
      };
      console.log(payload);
      const req = await defaultRequest();
      let response = await req.post(url, payload);
      if (response.data.response.status) {
        _virtualUpdateUserRating(response.data.response.data.ticket_history_ratings[0]);
      }
      return response.data.response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  updateTicketCommentRating: async function (id, rating, comment = '') {
    let orgId = CookieService.getCookie('OrganizationId');
    let url = `${orgId}/ticket_history_ratings/update`;
    try {
      let payload = {
        ticket_history_rating: {
          comment: comment,
          id: id,
          rating: rating,
        },
      };
      const req = await defaultRequest();
      let response = await req.put(url, payload);
      if (response.data.response.status) {
        _virtualUpdateUserRating(response.data.response.data.ticket_history_ratings[0]);
      }
      return response.data.response.data.ticket_carbon_copies;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  deleteTicketCommentRating: async function (id) {
    let orgId = CookieService.getCookie('OrganizationId');
    let url = `${orgId}/ticket_history_ratings/delete/${id}`;
    try {
      const req = await defaultRequest();
      let response = await req.delete(url);
      if (response.data.response.status) {
        _virtualDeleteUserRating(id);
      }
      return response.data.response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  saveTicketCarbonCopies: async function (id, users) {
    // create ticket carbon copies
    let users_params = '';
    for (let user_id of users) {
      users_params += '&ticket_carbon_copies[][user_id]=' + user_id;
    }

    let orgId = CookieService.getCookie('OrganizationId');
    let url = `${orgId}/ticket_carbon_copies/create?ticket_carbon_copies[][ticket_id]=${id}${users_params}`;
    try {
      const req = await defaultRequest();
      let response = await req.post(url);
      return response.data.response.data.ticket_carbon_copies;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  getCustomerShortname: async function () {
    // create ticket carbon copies

    let orgId = CookieService.getCookie('OrganizationId');
    let url = `${orgId}/customer/short_name`;
    try {
      const req = await defaultRequest();
      let response = await req.get(url);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  getCustomerTicketCarbonCopies: async function (filter, params = {}) {
    filter['user_id'] = await ConnectriaTicketsService.portalUserId(params);
    var preferences = await _getPreferences(filter, params);
    if (preferences && preferences.length > 0 && preferences[0].value) {
      return JSON.parse(preferences[0].value);
    } else {
      return [];
    }
  },
  downloadAttachment: async function (id) {
    return await _downloadAttachment(id);
  },
  getDevice: async function (deviceID, params = {}) {
    return await _getDevice(deviceID, params);
  },
  getAllDevices: async function (params = {}) {
    return await _getAllDevices(params);
  },
  getDeviceOSType: async function (id, params = {}) {
    return await _getDeviceOSType(id, params);
  },
  getDeviceIpBulk: async function (host_names = []) {
    let promises = [];
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    for (var i = 0; i < host_names.length; i++) {
      //name consist of device asset tag and .ipam.connpriv.com
      promises.push(req.get(`${orgId}/hosts/get?name=${host_names[i].toLowerCase() + '.ipam.connpriv.com'}`, {}));
    }
    return await Promise.all(promises);
  },
  getAllDevicesLike: async function (filter, params = {}) {
    return await _getAllDevicesLike(filter, params);
  },
  getAllDevicesById: async function (filter, params = {}) {
    return await _getAllDevicesById(filter, params);
  },
  getAllDeviceCount: async function (filter, params = {}) {
    return await _getAllDeviceCount(filter, params);
  },
  getAllDeviceTypes: async function (filter, params = {}) {
    return await _getAllDeviceTypes(filter, params);
  },
  getAllTicketsCount: async function (query, params = {}) {
    return await _getAllTicketsCount(query, params);
  },
  getAllTicketsLike: async function (query, params = {}) {
    return await _getAllTicketsLike(query, params);
  },
  exportAllTickets: async function (months = 3, params = {}) {
    return await _exportAllTickets(months, params);
  },
  portalUserId: async function (parmas = {}) {
    if (userId != null) {
      return userId;
    }
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    var request = await req.get(`${orgId}/user_id`, parmas);
    userId = request.data.user_id;
    return userId;
  },
  createTicket: async function (
    subject,
    issue,
    error,
    priorityId,
    typeId,
    deviceId,
    carbonCopies,
    categoryId,
    ticketMeta,
    doSentiment = false
  ) {
    var payload = {
      tickets: [
        {
          active: false,
          errorDesc: error,
          id: null,
          issue: issue,
          priority: priorityId,
          status: null,
          subject: subject,
          device: deviceId || 'undefined',
          ticket_carbon_copies: carbonCopies,
          ticket_type_id: typeId,
          ticketcategory_id: categoryId,
          ticket_meta: ticketMeta,
        },
      ],
    };
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    var request = await req.post(`${orgId}/tickets/create`, payload);
    return request.data.response.data.tickets[0].id;
  },
  createTicketMeta: async function (ticket_id, key, value) {
    var payload = {
      ticket_metum: {
        meta_key: key,
        meta_value: value,
        ticket_id: ticket_id,
      },
    };
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    var request = await req.post(`${orgId}/ticket_meta/create`, payload);
    return request.data.response.data;
  },
  deleteTicketMeta: async function (id) {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie('OrganizationId');
    var request = await req.delete(`${orgId}/ticket_meta/delete/${id}`);
    return request.data.response.data;
  },
  ticketPriorityMetric: async function (daysToInclude, params = {}) {
    if (ticketPriorityMetrics != null) {
      return ticketPriorityMetrics;
    }
    var payload = {
      select: 'priority,created',
      limit: '200',
      sort_by: 'created desc',
    };
    var raw = await _getTicketsFiltered(payload, params);
    var result = {
      labels: ['Emergency', 'High', 'Normal', 'Low'],
      datasets: [
        {
          data: [0, 0, 0, 0],
          backgroundColor: ['#dc3545', '#ffc107', '#777777', '#adb5bd'],
          hoverBackgroundColor: ['#dc3545', '#ffc107', '#777777', '#adb5bd'],
        },
      ],
    };
    for (var i = 0; i < raw.length; i++) {
      if (moment(raw[i].created, 'YYYY-MM-DDThh:mm:ss.SSSZ').days() > daysToInclude) {
        break;
      }
      result.datasets[0].data[raw[i].priority - 1] = result.datasets[0].data[raw[i].priority - 1] + 1;
    }
    ticketPriorityMetrics = result;
    return result;
  },
  closeTicket: async function (id, comment, rating) {
    await _closeTicket(id, comment, rating);
  },
  getChildTicket: async function (id) {
    return await _getChildTicket(id);
  },
  reopenTicket: async function (id, comment) {
    await _reopenTicket(id, comment);
  },
  ticketAudits: async function (limit, params = {}) {
    return await _getTicketAudits(limit, params);
  },
  clearTicketCache: function () {
    ticketDetail = null;
  },
  ticketDetail: async function (id, params = {}) {
    //ensure users and ticket types are loaded with ticket detail
    let promises = await Promise.all([_getTicketTypes(params), _getAllUsersLike(params), _getTicketDetail(id, params)]);
    let ticket = promises[2];
    this.updateUsersTicketCarbonCopies(ticket);

    return ticket;
  },
  ticketHistory: async function (id, params = {}) {
    return await _getTicketHistory(id, params);
  },
  newComment: async function (id, comment) {
    // clear ticket cache
    ticketDetail = null;
    ticketHistory = null;

    return await _postNewComment(id, comment);
  },
  addAttachment: async function (id, filename, content) {
    return await _addCommentAttachment(id, filename, content);
  },
  ticketCategories: async function (params) {
    return await _getTicketCategories(params);
  },
  allTicketsByStatus: async function (status, params = {}) {
    // return await _getAllTicketsByStatus(status);
    return await _getAllTicketsLike(
      {
        ar_assoc: 'user',
        limit: '0,100',
        select: 'tickets.id,subject,device,ticket_type_id,priority,created,status,user_id,lastUpdate',
        sort_by: 'lastUpdate desc',
        status,
      },
      params
    );
  },
  ticketTypes: async function (params = {}) {
    return await _getTicketTypes(params);
  },
  ticketType: function (id) {
    for (var i = 0; i < ticketTypes.length; i++) {
      if (ticketTypes[i].name == id) {
        return ticketTypes[i];
      }
    }
  },
  ticketPriorities: function () {
    return [
      {
        id: 1,
        name: '1 - Emergency',
        level: 'emergency',
      },
      {
        id: 2,
        name: '2 - High',
        level: 'high',
      },
      {
        id: 3,
        name: '3 - Normal',
        level: 'medium',
      },
      {
        id: 4,
        name: '4 - Low',
        level: 'low',
      },
    ];
  },
  ticketPriority: function (priority) {
    const priorities = ConnectriaTicketsService.ticketPriorities();
    if (typeof priority === "number") {
      return priorities.find((p) => p.id === priority);
    }
    if (typeof priority === "string") {
      return priorities.find((p) => p.name === priority);
    }
    return null;
  },
  inbox: async function (filter, params = {}) {
    var payload = {
      limit: filter.limit,
      select: 'tickets.id,subject,priority,created,status,user_id,ticket_type_id,lastUpdate',
      sort_by: filter.sort,
    };
    if (filter.statuses != null) {
      payload['status'] = filter.statuses;
    }
    if (filter.limit != null) {
      payload['limit'] = filter.limit;
      if (filter.offset != null) {
        payload['limit'] = `${filter.offset},${filter.limit}`;
      }
    }
    if (filter.justMine) {
      payload['user_id'] = await ConnectriaTicketsService.portalUserId(params);
    }

    if (filter.search != '') {
      if (filter.subjectOnly) {
        payload['subject'] = filter.search;
      } else {
        payload['issue'] = filter.search;
      }
      return await _getAllTicketsLike(payload, params);
    } else {
      return await _getTicketsFiltered(payload, params);
    }
  },
  getParamData: async function (params = {}) {
    return await _getParamData(params);
  },
  updateDevice: async function (data, params = {}) {
    return await _updateDevice(data, params);
  },
  getPortalDevice: async function (deviceId, params = {}) {
    return await _getPortalDevice(deviceId, params);
  },
};

export default ConnectriaTicketsService;
